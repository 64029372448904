// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';
// Set variables dependencies
var $ = require('jquery');
var Link = require('../_modules/link/link');
var gsap = require("gsap");
var ScrollMagic = require('scrollmagic');
var Parallax = require('parallax-js');
var WOW = require('wowjs').WOW;
var Swiper = require('swiper');
const Vivus = require('vivus');

// Load dependencies
require('bootstrap');
require('gsap/src/minified/plugins/CSSRulePlugin.min.js');
require('scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js');
require('scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js');
require('midnight.js/midnight.jquery.min.js');

$(window).on("load", function (){
    $(".wrapp-spinner").fadeOut(300);
    $("html, body").removeClass("no-overflow");
});

$(function () {
    new Link(); // Activate Link modules logic
    // console.log('Welcome to Yeogurt!');

    /* ---------------------------- Menu ----------------------------------- */
    $(".fix-to-pos").click(function () {
      $(this).toggleClass('active');


      if ($(this).hasClass('active')) {
        $(".vertical-txt span").text('chiudi menu');
      }
      else {
        $(".vertical-txt span").text('menu');
      }

        $(".menu-toggle").toggleClass("on");
        $('.menu-full').fadeToggle();
        $('.menu-full .links li').each(function(index, element) {
          $(this).toggleClass('animated fadeInLeft delay'+index+'');
        })
        $('.trademark').toggle();
        $('html,body').toggleClass('no-overflow');
    });

    if ($('main').hasClass('home')) {
      new Vivus('circular', {duration: 30});
      new Vivus('ellipsis', {duration: 30});
    }

    // OFFSET IS USED IN A LOT FUNCTION ( DECLARED JUST ONCE )
    var offset = $('.main-wrapp').offset().top;


    $('#scroll-down-arrow').click(function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop: (offset - 10 )}, 500);
    });


    var scene = document.getElementById('scene');
    var parallax = new Parallax(scene, {});

    var heightNav;
    var heightScene;

    heightNav = $('.navigation').outerHeight();
    heightScene = $('.scene-wrapper').outerHeight();
    $('#scene').css({"height": heightScene + heightNav, "margin-top": (heightNav + 300 )});
    $('.wrapp-scene').css({"height": heightScene + heightNav + 200});
    var heightStep = $('.second .row').outerHeight();
    var heightHero = $('.hero').outerHeight();

    $('.main-wrapp').css("margin-top", (heightHero / 2));

    $(window).on('resize', function () {
        heightNav = $('.navigation').outerHeight();
        heightScene = $('.scene-wrapper').outerHeight();
        heightHero = $('.hero').outerHeight();
        heightStep = $('.second .row').outerHeight();

        $('#scene').css({"height": heightScene + heightNav, "margin-top": (heightNav + 200 )});
        $('.wrapp-scene').css({"height": heightScene + heightNav + 200});
        $('.main-wrapp').css("margin-top", (heightHero / 2));
    });

    $(window).scroll(function () {
        var heigthFooter = $('footer').outerHeight();
        var footer = $('footer').offset().top;
        if ($(window).scrollTop() >= offset) {
            $('#svg-white .text').fadeOut(200);
            $('.svg-white').addClass('minimize');
            $('.arrow-follower').show(200);
        }
        else {
            $('#svg-white .text').fadeIn(200);
            $('.svg-white').removeClass('minimize');
            $('.arrow-follower').hide(200);
        }

        if ($(window).scrollTop() >= (footer - heigthFooter)) {
          $('.arrow-follower').addClass('rotate-top');
        }
        else {
          $('.arrow-follower').removeClass('rotate-top');
        }
    })



    $('.arrow-follower').click(function(e){
      e.preventDefault();
      if ( $(this).hasClass('rotate-top')) {
        $('html, body').animate({ scrollTop : 0}, 500)
      }
    });


    var theSwiper = new Swiper('#projects', {
        slidesPerView: 3,
        speed: 400,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true
        },
        breakpoints: {
            // when window width is <=
            991: {
                slidesPerView: 2,
            },
            767: {
                slidesPerView: 1,
            }
        }
    });


    $('.block-1').each(function() {
    	var $this = this;
    	$('> :gt(0)', $this).hide();
    	setInterval(function(){
			     $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);
      },3200);
    });
    $('.block-2').each(function() {
      var $this = this;
      $('> :gt(0)', $this).hide();
      setTimeout(function(){
        setInterval(function(){
          $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
        3200);
      }, 2000);
    });
    $('.block-3').each(function() {
        var $this = this;
        $('> :gt(0)', $this).hide();
        setTimeout(function(){
          setInterval(function(){
            $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
          3200);
        }, 5000);
      });
    $('.block-4').each(function() {
        var $this = this;
        $('> :gt(0)', $this).hide();
        setTimeout(function(){
          setInterval(function(){
            $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
          3200);
        }, 4000);
      });
    $('.block-5').each(function() {
      var $this = this;
      $('> :gt(0)', $this).hide();
      setTimeout(function(){
        setInterval(function(){
          $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
        3200);
      }, 3000);
    });
    $('.block-6').each(function() {
      var $this = this;
      $('> :gt(0)', $this).hide();
      setTimeout(function(){
        setInterval(function(){
          $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
        3200);
      }, 6000);
    });
    $('.block-7').each(function() {
      var $this = this;
      $('> :gt(0)', $this).hide();
      setTimeout(function(){
        setInterval(function(){
          $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
        3200);
      }, 5500);
    });
    $('.block-8').each(function() {
        var $this = this;
        $('> :gt(0)', $this).hide();
        setTimeout(function(){
          setInterval(function(){
            $('> :first-child',$this).fadeOut().next().fadeIn().end().appendTo($this);},
          3800);
        }, 8000);
    });




    /* ---------------------------- Init wow ----------------------------------- */
    new WOW().init();

    /* ---------------------------- Init Swiper ----------------------------------- */
    $('.the-step .swiper-container').css("height", (heightStep + 100));
    var step1 = new Swiper('#the-step', {
        direction: 'vertical',
        slidesPerView: 1,
        speed: 500,
        pagination: {
            el: '.custom-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              return '<div class="d-flex align-items-center justify-content-end wrapp-bullet '+className+'"><span class="number">' + (index + 1) + '</span><span class="line"></div>';
            }
        }
    });


    step1.on('transitionStart', function () {
        var active = $('.the-step .swiper-slide-active').data('number');
        if (active === "first") {
            $("#bk-anim").fadeIn(500);
            $('#svg-white path').removeClass('fillinst');
            $('.custom-pagination .wrapp-bullet').removeClass('institutionl-change');
        }
        else if (active === "second") {
            $('#bk-anim').fadeOut(500);
            $('#svg-white path').addClass('fillinst');
            $('.custom-pagination .wrapp-bullet').addClass('institutionl-change');
        }
        else if (active === "three") {
            $("#bk-anim").fadeIn(500);
            $('#svg-white path').removeClass('fillinst');
            $('.custom-pagination .wrapp-bullet').removeClass('institutionl-change');
        }
    });

    // Adding text to card on hover
    $('.wrapp-all-card .swiper-slide').hover(function (e) {
        $(this).find('.hover-content').fadeIn(100);
    }, function (e) {
        $(this).find('.hover-content').fadeOut(100);
    });

    /* ---------------------------- Init Scrollmagic ----------------------------------- */
    var initSM = new ScrollMagic.Controller();

    var timeline_1 = new TimelineMax()
        .add(TweenMax.to(".scene", 0.5,
            {
                scale: 0.90
            }
        ))
        .add(TweenMax.to(".hero", 0.5,
            {
                position: "fixed"
            }
        ));

    var scena_1 = new ScrollMagic.Scene(
        {
            duration: 500,
            triggerHook: "onCenter"
        })
        .setTween(timeline_1)
        .setPin(".hero")
        //.addIndicators({name: "scena-1"})
        .addTo(initSM);

    scena_1.on("end", function () {
        var ruleWrappScene = CSSRulePlugin.getRule("main .hero:before");
        var timeline_2 = new TimelineMax()
            .add(TweenMax.to(ruleWrappScene, 0.5,
                {
                    cssRule: {
                        opacity: 1
                    }
                }
            ))
            .add(TweenMax.to(".hero", 0.5,
                {
                    opacity: 0
                }
            ));

        var scena_2 = new ScrollMagic.Scene(
            {
                offset: 500,
                duration: 1000,
                triggerHook: "onCenter"
            })
            .setTween(timeline_2)
            //.addIndicators({name: "scena-2"})
            .addTo(initSM);
    });

    // Change this to the correct selector for your nav.
    $('.trademark').not('.white-scroll').midnight();

    console.clear();
});
